/**
 * @license
 * MyFonts Webfont Build ID 3376733, 2017-04-19T15:05:57-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed ../fonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: URWGeometric-Light by URW++
 * URL: http://www.myfonts.com/fonts/urw/geometric/light/
 *
 * Webfont: URWGeometric-Regular by URW++
 * URL: http://www.myfonts.com/fonts/urw/geometric/regular/
 *
 * Webfont: URWGeometric-SemiBold by URW++
 * URL: http://www.myfonts.com/fonts/urw/geometric/semi-bold/
 *
 * Webfont: URWGeometric-ExtraBold by URW++
 * URL: http://www.myfonts.com/fonts/urw/geometric/extra-bold/
 *
 * Webfont: URWGeometric-Black by URW++
 * URL: http://www.myfonts.com/fonts/urw/geometric/black/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3376733
 * Licensed pageviews: 20,000
 * ../fonts copyright: (URW)++,Copyright 2016 by (URW)++ Design &amp; Development
 *
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/33865d");


@font-face {
  font-family: 'URWGeometric';
  src: url('../fonts/33865D_0_0.woff2') format('woff2');

  font-style: normal;
  font-weight: 200;
}


@font-face {
  font-family: 'URWGeometric';
  src: url('../fonts/33865D_1_0.woff2') format('woff2');

  font-style: normal;
  font-weight: 400;
}


@font-face {
  font-family: 'URWGeometric';
  src: url('../fonts/33865D_2_0.woff2') format('woff2');

  font-style: normal;
  font-weight: 600;
}


@font-face {
  font-family: 'URWGeometric';
  src: url('../fonts/33865D_3_0.woff2') format('woff2');

  font-style: normal;
  font-weight: 900;
}


@font-face {
  font-family: 'URWGeometric-Black';
  src: url('../fonts/33865D_4_0.woff2') format('woff2');

  font-style: normal;
  font-weight: 400;
}