// Variables
//----------

// Colors
// --------------------------------------------------------
// Neutrals
$neutral60: #4D4D4F;
$neutral20: #F4F4F4;

$neutralWhite: #ffffff;
$neutralOffBlack: $neutral60;


// Accent Colors
$accentPrimary: #007FA3;
$accentPrimaryDark: #04576F;
$accentPrimaryLight: #ACE3EF;

$accentSecondary: #FF883E;
$accentSecondaryDark: #D07035;

$accentTertiary: #FFDD35;

$error: #A32400;

// Typographic hierarchy
// --------------------------------------------------------
// $type110: ;
// $type100: ;
// $type90: ;
// $type80: ;
// $type70: ;
// $type60: ;
// $type50: ;
// $type40: ;
// $type30: ;
// $type20: ;
// $type10: ;

// Measurements
// --------------------------------------------------------
$containerPadding: 4.688vw;
$containerPaddingMax: 45px;

$containerWidth: 960px;
$containerWidthMax: $containerWidth + ($containerPaddingMax * 2);

$baseLineHeight: 1.4;
$verticalSpace: $baseLineHeight * 1em;

$borderSizes: (5px, 7px);

$durationFast: 0.1s;
$duration: 0.2s;
$durationSlow: 0.4s;
$timing: ease;

// Font Weights
// --------------------------------------------------------
$light: 200;
$regular: 400;
$semibold: 600;
$extrabold: 900;

// Responsive breakpoints
// --------------------------------------------------------
$break150: 1612px;
$break130: 1300px;
//$break120: ;
$break110: $containerWidthMax;
$break100: 1024px;
$break90: $containerWidth;
$break80: 880px;
$break75: 750px;
$break70: 730px;
$break65: 680px;
$break60: 630px;
$break50: 560px;
$break40: 460px;
$breakNav: $break70;
// $break30: ;
// $break20: ;
// $break10: ;
