.stats-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  @supports(grid-template-columns: 1fr) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }

  @include respond($break65) {
    @supports(grid-template-columns: 1fr 1fr 1fr) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 70px;
    }

    &--two-columns {
      @supports(grid-template-columns: 1fr 1fr) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 70px;
      }
    }
  }

  .stat-container {
    flex-basis: auto;
    padding: 16px 0;
    position: relative;

    @include respond($break65) {
      flex: 1;
      padding: 16px 28px;
    }

    &:not(:nth-child(3n+3), :last-child)::after {
    display: block;
    content: "";
    width: 235px;
    height: 4px;
    background-color: $accentPrimaryLight;
    margin-top: 20px;

      @include respond($break65) {
        width: 4px;
        height: 74px;
        position: absolute;
        right: 0;
        top: 0
      }
    }
  }

  h2 {
    font-weight: $extrabold;
    font-size: 90px;
    padding-bottom: 0.2em;
    padding-top: 0;
    line-height: 0.8;
    text-align: left;

    @include respond($break65) {
      text-align: center;
    }
  }

  .description {
    p, li {
      color: $accentPrimary;
      font-weight: $semibold;
      line-height: 1.2;
      font-size: 16px;
    }
  }
}
