.m-members-list {
  .sponsor-list {
    column-count: 1;

    .heading-secondary::after {
      display: block;
      width: 25px;
      height: 6px;
      background-color: $accentPrimary;
      content: '';
      transition: background-color $duration $timing;
    }

    &__logos {
      column-span: all;
    }

    &__list {
      margin-top: 20px;
      margin-bottom: 50px;

      @include respond($break60) {
        column-count: 2;
      }
    }

    &__grid {
      margin-bottom: 3em;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > li {
        flex: 1 0 calc(33% - 1em);
        margin: 1em .5em 2em .5em;
      }

      &--two-columns > li {
        flex: 1 0 calc(50% - 1em);
      }
    }
    
    &__grid-item {
      text-align: center;
      
      &__description {
        color: $neutralOffBlack;
      }

      > span {
        min-height: 3em;
      }
    }

    &__logo {
      display: flex;
      align-items: flex-end;
      margin: 1em 2em;

      @include respond($break65) {
        height: 100px;
      }

      img {
        max-width: 195px;
        max-height: 100px;
        margin: 0 auto;
      }
    }
  }
}
