.location {
  .map-container {
    width: 100%;
    max-width: 100%;
    padding-top: 28px;

    .map {
      width: 100%;
      max-width: 100%;
      height: 0;
      padding-top: 65.289%;
    }
  }

  + .location {
    padding-top: 49px;
  }
}