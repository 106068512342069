.hamburger-overlay {
  background-color: $accentPrimaryLight;
  position: absolute;
  top: 0;
  right: -100vw;
  max-height: 100vh;
  width: 380px;
  max-width: 100%;
  box-shadow: 1000px -1000px 0px 1000px $accentPrimaryLight;
  transition: top $duration $timing,
    right $duration $timing;
  overflow: auto;

  @include respond($break80) {
    display: none;
  }

  @include respond($break40) {
    min-width: 385px;
  }

  .headroom--unpinned & {
    top: 100px;

    @include respond($break60) {
      top: 0;
    }
  }

  &.open {
    right: 0;
  }

  .close-overlay {
    @include buttonUnstyled;
    @include iconPre($x);
    color: $accentPrimary;
    float: right;
    font-size: 28px;
  }
}

.hamburger-nav {
  clear: both;
  @include fontSign;
  font-size: 22px;
  padding: 12px 30px 40px $containerPadding;

  @include respond($break40) {
    padding: 20px 30px 60px;
  }


  @include respond($break70) {
    padding: 32px 0 88px 51px;
  }

  .pages {
    @include listUnstyled;
    padding-bottom: 42px;

    @include respond($break40) {
      columns: 2;
      column-gap: 45px;
    }

    @include respond($break70) {
      margin-bottom: 56px;
    }

    > li {
      padding-bottom: 38px;
      break-inside: avoid;

      @include respond($break70) {
        padding-bottom: 57px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    > li > a, .nav-sub-header {
      color: $accentPrimary;
      display: block;
      padding-bottom: 2px;
      transition: color $duration $timing;

      &:hover {
        color: $neutralWhite;

        &::after {
          background-color: $neutralWhite;
        }
      }

      &::after {
        display: none;
        width: 25px;
        height: 6px;
        background-color: $accentPrimary;
        content: '';
        transition: background-color $duration $timing;
      }
    }

    .nav-sub-header {

      &:hover {
        color: $accentPrimary;

        &::after{
          background-color: $accentPrimary;
        }
      }
    }
  }

  .sub-pages {
    @include listUnstyled;
    margin-top: 2px;

    li {
      &.active a {
        @include fontSign;
        color: $neutralWhite;
      }

      + li {
        margin-top: 0.333em;
      }

      a {
        @include fontBase;
        font-size: 20px;
        font-weight: $regular;
        color: $accentPrimary;
        transition: color $duration $timing;

        &:hover {
          @include fontSign;
          color: $neutralWhite;
        }
      }
      span {
        @include fontBase;
        font-size: 20px;
        font-weight: $regular;
        color: $accentPrimary;
      }
    }
    &.grandchildren {
      margin-left: 20px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .button-primary {
      margin-bottom: 48px;

      @include respond($break40) {
        margin-bottom: 0;
      }
    }

    .social {
      display: inline-block;
      height: 32px;

      li {
        display: inline-block;

        + li {
          margin-left: 5px;
        }
      }

      a {
        font-size: 28px;
        color: $neutralWhite;
      }
    }
  }

}
