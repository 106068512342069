.search-results {
  .search-form {
    input[type="text"] {
      font-size: 20px;

      @include respond($break40) {
        width: 350px;
      }
    }

    .search-submit::before {
      padding: 10px 10px 9px;
    }
  }


  .results-list {
    padding-top: 32px;

    ul {
      @include listUnstyled;

      li {
        + li {
          margin-top: 38px;

          @include respond($break60) {
            margin-top: 63px;
          }
        }

        a {
          display: block;
          color: $neutralOffBlack;

          &:hover {
            .arrow-right-link::before {
              left: 12px;
            }
          }

          .title {
            @include headingPrimary;
            color: $accentPrimary;
          }
        }

        .arrow-right-link {
          margin-top: 32px;
          margin-bottom: 10px;
        }

        .border-bottom {
          @include borderSize(height);
          position: relative;
          width: 100%;
          background-color: $accentPrimary;
        }
      }
    }
  }
}