// Donezo Generated Icon File:
// File will be overwritten if regenerated

// Icon Variables
// --------------------------------------------------------
$arrowDown: '\e900';
$arrowLeftShort: '\e901';
$arrowLeft: '\e902';
$arrowRightShort: '\e903';
$arrowRight: '\e904';
$arrowUp: '\e905';
$caretDown: '\e906';
$caretLeft: '\e907';
$caretRight: '\e908';
$caretUp: '\e909';
$check: '\e90a';
$facebook: '\e90b';
$hamburger: '\e90c';
$linkedIn: '\e90d';
$magnify: '\e90e';
$twitter: '\e90f';
$x: '\e910';
$youtube: '\e911';
$mail: '\ea86';


@font-face {
  font-family: 'forth-icons';
  src: url('../fonts/forth-icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@mixin fontIcon {
  font-family: 'forth-icons', sans-serif;
}

@mixin protoIcon {
  @include fontIcon;
  display: inline-block;
  vertical-align: baseline;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
}

@mixin iconPre($icon) {
  speak: none;

  &::before {
    @include protoIcon;
    content: $icon;
  }
}

@mixin iconPost($icon) {
  speak: none;

  &::after {
    @include protoIcon;
    content: $icon;
  }
}
