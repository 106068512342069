.search-form {
  input[type="text"] {
    display: inline-block;
    width: auto; // OD
    vertical-align: middle;
    font-size: 15px;
    background-color: $neutral20;
    border: 0;
    outline: 0;
    padding: 6px 10px 2px;
    border-bottom: 3px solid $neutral20;
    transition: border-color $duration $timing;
    
    @include respond($break40) {
      padding: 9px 10px 5px;
    }

    &:focus {
      border-color: $accentPrimaryLight;
      padding: 6px 10px 2px;

      @include respond($break40) {
        padding: 9px 10px 5px;
      }
    }

    &::placeholder {
      color: $accentPrimary;
    }
  }

  .search-submit {
    @include buttonUnstyled;
    @include iconPre($arrowRight);
    vertical-align: middle;

    &::before {
      color: $neutralWhite;
      background-color: $accentPrimary;
      font-size: 22px;
      padding: 4px 5px 5px;
      margin-top: 1px;

      @include respond($break40) {
        font-size: 24px;
        padding: 7px 6px 6px;
        margin: 0;
      }
    }
  }
}