$_spacing: 2rem;

.m-nav {
  flex: 1 1 auto;
  margin-left: $_spacing;
  margin-right: $_spacing;

  @include respond($breakNav, 'max') {
    display: none;
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
    align-content: baseline;
  }

  &__item {
    color: $accentPrimary;
    transition: color 70ms $timing;
    position: relative;
    overflow: visible;

    & + & {
      margin-left: $_spacing;
    }

    &:hover {
      color: $accentSecondary;

      &::after {
        background-color: $accentSecondary;
        width: 100%;
      }
    }

    &::after {
      display: none;
      width: 25px;
      height: 6px;
      background-color: $accentPrimary;
      content: '';
      transition: background-color $duration $timing, width $duration $timing;
    }

    &:focus-within,
    &:hover {
      .m-nav__secondary-list {
        animation-name: fadein;
        animation-duration: $duration;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        max-height: inherit;
        overflow: auto;
      }
    }

    &-header {
      pointer-events: none;
    }
  }

  &__link {
    color: inherit;
    display: inline-block;
    padding-bottom: 2px;
    transition: color $duration $timing;
    min-width: 60px;

    &--highlight {
      color: $neutralWhite;
      font-weight: 600;
      text-align: center;
    }

    &--highlight:before {
      content:"";
      background: $accentPrimary;
      position: absolute;
      height: 115%;
      top: -15%;
      width: calc(100% + 1.4rem);
      left: -.7rem;
      z-index: -2;
    }

    &--highlight:focus-within:before,
    &--highlight:hover:before {
      background: darken($accentSecondary, 5%);
    }

  }

  &__secondary-list {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    background: transparent;
    list-style: none;
    padding: 6px 0 0 0;
  }

  &__secondary-item {
    background: $neutral20;
    padding: .25rem;

    &:first-child {
      padding-top: .5rem;
    }

    &:last-child {
      padding-bottom: .75rem;
    }
  }

  &__secondary-link,
  &__tertiary-link {
    display: block;
    color: darken($accentPrimary, 5%);
    transition: color 150ms $timing;
    white-space: nowrap;

    &:hover {
      color: darken($accentSecondary, 5%);
    }
  }

  &__secondary-link {
    font-weight: bold;
    padding: 0 1rem;
    line-height: 2.5rem;
  }

  &__secondary-header {
    display: block;
    color: darken($accentPrimary, 5%);
    transition: color 150ms $timing;
    white-space: nowrap;
    font-weight: bold;
    padding: 0 1rem;
    line-height: 2.5rem;
    pointer-events: none;
  }

  &__tertiary-link {
    line-height: 2rem;
    padding-right: 1em;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
