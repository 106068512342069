// Content 
// -------

// Import Froala RTE defaults
// --------------------------------------------------------
@import 'froala';

// RTE
// --------------------------------------------------------
.rte-content, .content-default {
  p {
    font-size: 16px;

    &.accent {
      color: $accentPrimary;

      .accent-secondary & {
        color: $accentSecondary;
      }
    }

    @include respond($break40) {
      font-size: 18px;
    }
  }

  a {
    @include linkPrimary;
  }

  .lead {
    font-size: 23px;
    color: $neutralOffBlack;
    padding-bottom: 1em;
  }

  .lead-accent {
    font-size: 25px;
    font-weight: bold;
    color: $accentSecondary;
    padding-bottom: 1em;
  }

  h1, h2, h3, h4 {
    color: $accentPrimary;

    .bg-accent & {
      color: $neutralWhite;
    }

    .accent-secondary & {
      color: $accentSecondary;
    }

    &.no-space-bottom {
      padding-bottom: 0;
    }
  }

  h1, .heading-primary {
    @include headingPrimary;
    padding-top: 0;
    padding-bottom: 0.302em;
  }

  h2, .heading-secondary {
    @include headingSecondary;
    padding-top: 0.5em;
    padding-bottom: 1em;
  }

  h3, h4, .heading-tertiary {
    @include headingTertiary;
    padding-top: 0.6em;
    padding-bottom: 0.9em;
  }

  ul, ol {
    @include listUnstyled;
    font-size: 16px;
    
    @include respond($break40) {
      font-size: 18px;
    }

    li {
      position: relative;
      padding-left: 18px;

      + li {
        margin-top: 17px;
      }
    }
  }

  ul li {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "•";
      color: $accentPrimary;

      .accent-secondary & {
        color: $accentSecondary;
      }
    }
  }

  ol {
    counter-reset: content-ol;

    li:before {
      @include fontSign;
      position: absolute;
      top: 0;
      left: 0;
      content: counter(content-ol) '.';
      counter-increment: content-ol;
      color: $accentPrimary;

      .accent-secondary & {
        color: $accentSecondary;
      }
    }
  }
}