// Bundles kiosk styles
.background-kiosk {
  width: 100%;
  height: 100vh;
  display: flex;

  &.tablet {
    overflow: hidden;
    display: flex;

    .flourish-top, .flourish-bottom {
      position: absolute;
      width: 100vw;

      img {
        display: block;
      }
    }

    .flourish-top {
      bottom: 100%;
      left: 0;
      position: absolute;
      z-index: -1;
    }

    .flourish-bottom {
      position: absolute;
      z-index: -1;
      top: 100%;
      right: 0;
    }

    .flourish-container {
      position: relative;
      padding-top: 5vh;
      padding-bottom: 5.5vh;
      min-height: 32.746vh;
      width: 100vw;
      background-color: $accentPrimaryLight;
      margin: auto;
      z-index: 5;
    }

  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .flourish-center {
    margin: auto;
    width: 100%;

    .flourish-top, .flourish-bottom {
      width: 100%;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .flourish-container {
      min-height: 32.746vh;
      background-color: $accentPrimaryLight;

      .container {
        max-width: 100%;
        padding-right: 7.134vw;
        padding-left: 7.134vw;
      }
    }
  }
}

.header-kiosk, .footer-kiosk {
  @include clearfix();
  padding: 60px 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $neutralWhite;
  z-index: 6;

  &.tablet {
    padding: 25px 0 18px;

    .logo {
      max-width: 345px;
    }

    .button-primary {
      margin-top: -6px; // NP
      padding: 0.5em 0.531em 0.2em;
      @include respond($break80, max) {
        font-size: 2.3vw;
      }
    }
  }

  .logo {
    display: block;
    width: 38.957vw;
    max-width: 628px;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .button-primary {
    float: right;
    font-size: 1.985vw;
    background-color: $accentPrimaryLight;
    padding: 0.55em 0.531em 0.3em;
  }

  .start-over-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity $timing $durationSlow, visibility $timing $durationFast $durationSlow;

    &.active {
      opacity: 1;
      visibility: visible;
      transition: opacity $timing $durationSlow;
    }
  }
}

.footer-kiosk {
  top: auto;
  bottom: 0;
  left: 0;
}

.page-wrapper-kiosk {
  position: relative;
  padding: 160px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;

  &.tablet {
    padding: 45px 0 0;
    .question {
      .query {
        font-size: 3.9vw;
        @include respond($break80) {
          font-size: 3.2vw;
        }
      }

      .answers .parent {
        font-size: 3vw;
        @include respond($break80) {
          font-size: 2.2vw;
        }
      }

      .answers .child {
        font-size: 2.35vw;
        @include respond($break80) {
          font-size: 1.8vw;
        }
      }
    }

    .match-results {
      .vehicle {
        .stats.image {
          @include respond($break80, max) {
            font-size: 2vw;
          }
        }
      }
    }
  }

  .overlay {
    width: 100%;
    max-width: 75.434vw;
    margin: 0 auto;
    background-color: $neutralWhite;
    flex-direction: column;
    padding: 6vw 0 3vw;

    > div {
      margin: auto;
      width: 100%;
    }
  }

  // Survey style overrides
  .match-survey, .match-results {
    .container {
      max-width: 100%;
      padding-right: 7.134vw;
      padding-left: 7.134vw;
    }
  }

  .question .query {
    font-size: 3.598vw;
  }

  .question {
    padding-bottom: 1.861vw;

    .answers .parent {
      font-size: 2.605vw;
    }

    .answers .child {
      font-size: 1.737vw;
    }
  }

  .next-button {
    font-size: 2.295vw;
  }

  .match-results {
    .success .heading-secondary {
      font-size: 2.605vw;
    }

    .vehicle-list {
      header h2 {
        font-size: 3.598vw;
      }
    }

    .circle-link {
      font-size: 2.295vw;
      width: 4.7em;
      height: 4.7em;
      background-color: $accentPrimaryLight;
    }

    .vehicle {
      .stats.image {
        width: calc(100% - 26vw);
        font-size: 1.737vw;
        padding-right: 2.2vw;
      }

      .body figure {
        width: 26vw;
      }
    }
  }

  .content-block-outlined {
    .heading-secondary {
      font-size: 2.605vw;
    }
  }

  .direct-mail {
    font-size: 1.489vw;
  }

  .circle-link-nav {
    max-width: 50.620vw;

    li a {
      width: 4.7em;
      height: 4.7em;
      font-size: 2.295vw;
    }
  }

  .button-restart {
    display: none;
  }
}
