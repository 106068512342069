.video-gallery {
    padding-top: 2em;
    display: flex;
    flex-wrap: wrap;

    @supports(grid-template-columns: 1fr) {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 50px;
    }

    @include respond($break65) {
        @supports(grid-template-columns: 1fr 1fr 1fr) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-row-gap: 70px;
            grid-column-gap: 2em;
        }
    }

    .video-item {
        .caption {
            color: $accentPrimary;
            font-weight: $semibold;
            line-height: 1.5;
            font-size: 16px;
            padding-top: 1em;
        }
    }
}