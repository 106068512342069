// Component Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc. as well
// as some minor, internal structural properties where abstraction isn't required (to prevent over-abstracting)

// They may contain:
// Nested classes/elements with other structural or textural properties

// Appearance blocks should not contain typographic classes nor be dependent on the font-size from the element, unless
// it is as a non-dependent, comprehensive mixin (for now), or if the font size is being used for structural reasons (like the size of an icon-font glyph)

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms

// Basic utility patterns
// --------------------------------------------------------
// Visually Hidden, for skip links and screen-reader only elements
@mixin screenReaderText {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

// Image replacement, for icons and things with hidden accessibility text
@mixin imageReplacement {
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;

  br {
    display: none;
  }
}

.screen-reader-text {
  @include screenReaderText;
}

.qq-invisible {
  // Special Queequeg class to hide injected data
  display: none;
}

.circle-link-only {
  display: none;

  .circle-link & {
    display: inline;
  }
}

// Layout
// --------------------------------------------------------
.bg-accent {
  background-color: $accentPrimary;
  color: $neutralWhite;
}

.bg-accent-light {
  background-color: $accentPrimaryLight;
}

.bg-accent-tertiary {
  background-color: $accentTertiary;
  color: $accentPrimary;
}

.bg-neutral20 {
  background-color: $neutral20;
}

// Apply a responsive, unified border size to elements
@mixin borderSize($parameter, $direction: positive) {

  #{$parameter}: if($direction == positive, nth($borderSizes, 1), - nth($borderSizes, 1));

  @include respond($break70) {
    #{$parameter}: if($direction == positive, nth($borderSizes, 2), - nth($borderSizes, 2));
  }
}

.flourish-top-primary {
  width: 100%;
  height: 95px;
  background-size: cover;
  background-image: url('../images/flourish01_top.png');
  background-repeat: no-repeat;
  background-position: 50% 0;

  @include respond($break60) {
    height: 160px;
    background-size: auto;
  }
}

.flourish-bottom-primary {
  width: 100%;
  height: 125px;
  background-size: cover;
  background-image: url('../images/flourish01_bottom.png');
  background-repeat: no-repeat;
  background-position: 50% 0;

  @include respond($break60) {
    height: 250px;
    background-size: auto;
  }
}

.flourish-top-secondary {
  width: 100%;
  height: 120px;
  background-size: cover;
  background-image: url('../images/flourish02_top.png');
  background-repeat: no-repeat;
  background-position: 50% 0;

  @include respond($break60) {
    height: 245px;
    background-size: auto;
  }
}

.flourish-bottom-secondary {
  width: 100%;
  height: 125px;
  background-size: cover;
  background-image: url('../images/flourish02_bottom.png');
  background-repeat: no-repeat;
  background-position: 50% 0;

  @include respond($break60) {
    height: 250px;
    background-size: auto;
  }
}

.flourish-top-ternary {
  width: 100%;
  height: 121px;
  background-image: url('../images/flourish04_top.png');
  background-repeat: no-repeat;
  background-position: 50% 0%;

  .bg-accent-light & {
    background-image: url('../images/flourish03_top.png');
  }
}

.flourish-bottom-ternary {
  width: 100%;
  height: 123px;
  background-image: url('../images/flourish04_bottom.png');
  background-repeat: no-repeat;
  background-position: 50% 0%;

  .bg-accent-light & {
    background-image: url('../images/flourish03_bottom.png');
  }
}


// Buttons, Links
// --------------------------------------------------------
@mixin linkPrimary {
  @include fontSign;
  color: $accentPrimary;
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 7px 7px;
  transition: background-image $duration $timing;

  &:hover {
    background-image: linear-gradient($accentPrimaryLight, $accentPrimaryLight);

    .bg-accent-light & {
      background-image: linear-gradient($neutralWhite, $neutralWhite);
    }
  }

  .accent-secondary & {
    color: $accentSecondary;
  }
}

.button-primary {
  @include buttonUnstyled;
  @include utilityPrimary;
  display: inline-block;
  background-color: $accentPrimary;
  color: $neutralWhite;
  line-height: 27px;
  padding: 3px 10px 2px;

  .accent-secondary &, &.accent-secondary {
    background-color: $accentSecondary;
  }

  &.light {
    background-color: $accentPrimaryLight;
  }
}

.arrow-right-link {
  @include utilitySecondary;
  @include iconPre($arrowRight);
  position: relative;
  display: inline-block;
  color: $accentPrimary;
  padding-left: 64px;

  &:hover {
    &::before {
      left: 12px;
    }
  }

  .accent-secondary & {
    color: $accentSecondary;
    border-color: $accentSecondary;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    vertical-align: middle;
    margin-top: -23px;
    margin-right: 16px;
    font-size: 28px;
    padding: 0.143em;
    color: $neutralWhite;
    background-color: $accentPrimary;
    transition: left $duration $timing;

    .accent-secondary & {
      background-color: $accentSecondary;
    }
  }
}

.arrow-down-link {
  @include utilitySecondary;
  @include iconPre($arrowDown);
  display: block;
  color: $accentSecondary;

  &.accent-light::before {
    background-color: $accentPrimaryLight;
  }

  &::before {
    display: inline-block;
    font-size: 24px;
    padding: 0.18em 0.143em 0.11em 0.143em ;
    color: $neutralWhite;
    background-color: $accentSecondary;
  }
}

.circle-link {
  display: table;
  width: 96px;
  height: 96px;
  background-color: $accentPrimaryLight;
  border-radius: 100%;
  text-align: center;
  color: $neutralWhite;
  font-size: 19px;
  letter-spacing: -0.01em;
  font-weight: $extrabold;
  line-height: 1;

  @include respond($break40) {
    width: 116px;
    height: 116px;
    font-size: 22px;

    &.small {
      width: 96px;
      height: 96px;
      font-size: 19px;
    }
  }


  span {
    display: table-cell;
    vertical-align: middle;
    padding-top: 0.35em;
  }
}

.mobile-hide {
    display: none;
    @include respond($break60) {
        display: block;
    }
}

.button-callout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $accentPrimary;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: $light;
  padding: 0.865em 1.5em 0.73em;
  border: 0 solid $accentPrimaryLight;
  @include borderSize(border-top-width);
  @include borderSize(border-right-width);
  transition: color $duration $timing, border $durationSlow, $timing;


  &:hover {
    // @include fontSign;
    color: $accentPrimaryDark;
    border-color: $accentPrimary;
    transition : border 500ms ease-out;

    .arrow-right::after {
      transform: translateX(15px);
      // color: $accentPrimaryDark;
      // background-color: transparent;
    }

    &::before, &::after {
      background-color: $accentPrimary;
    }
  }

  .bg-accent-light &, .background-kiosk & {
    color: $neutralWhite;
    border-color: $accentPrimary;

    &::before, &::after {
      background-color: $accentPrimary;
    }

    &:hover {
      // @include fontSign;
      border-color: $accentPrimaryDark;
      color: $accentPrimaryDark;

      .arrow-right::after {
        transform: translateX(15px);
        // color: $accentPrimaryDark;
        // background-color: transparent;
      }

      &::before, &::after {
        background-color: $accentPrimaryDark;
      }
    }
  }

  @include respond($break40) {
    font-size: 27px;
    padding-right: 1.2em;
  }

  @include respond($break60) {
    font-size: 4vw;
  }

  @include respond($break80) {
    font-size: 37px;
  }

  &::before, &::after {
    position: absolute;
    background-color: $accentPrimaryLight;
    display: block;
    content: '';
    transition : background-color $durationSlow $timing;

    .bg-accent-light & {
      background-color: $accentPrimary;
    }
  }

  &::before {
    top: 0;
    left: 0;
    @include borderSize(width);
    height: 67.544%;
  }

  &::after {
    bottom: 0;
    right: 0;
    width: 59.69%;
    height: 7px;
    @include borderSize(height);
  }

  .arrow-right {
    @include iconPost($arrowRight);

    &::after {
      font-size: 0.8em;
      line-height: 1;
      padding: 4px;
      margin-left: 1.286em;
      color: $neutralWhite;
      background-color: $accentSecondary;
      transform: translateX(0);
      transition: transform $timing $durationSlow;

      .bg-accent-light & {
        background-color: $accentTertiary;
      }

      .showcase-callout & {
        background-color: $accentTertiary
      }
    }

    &.accent-tertiary {
      &::after {
        background-color: $accentTertiary;
      }
    }
  }
}

@mixin linkUnderlined {
  @include fontSign;
  position: relative;
  font-size: 15px;
  display: inline-block;
  color: $accentPrimary;

  &:hover {
    color: $accentSecondary;

    .accent-secondary & {
      color: $accentPrimary;
    }

    &::after {
      background-color: $accentSecondary;

      .accent-secondary & {
        background-color: $accentPrimary;
      }
    }
  }

  .accent-secondary & {
    color: $accentSecondary;
  }

  &::after {
    position: absolute;
    bottom: 1px;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    content: '';
    background-color: $accentPrimary;

    .accent-secondary & {
      background-color: $accentSecondary;
    }
  }
}

.back-link {
  @include linkUnderlined;
  @include iconPre($arrowLeftShort);
  position: relative;
  padding-left: 42px;

  &::after {
    left: 42px;
    right: 0;
    width: auto;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.267em;
  }
}

.linked-in-link {
  @include iconPre($linkedIn);
  color: $accentPrimary;
  font-size: 24px;
  transition: color $duration $timing;
}

.mail-link {
  @include iconPre($mail);
  color: $accentPrimary;
  font-size: 24px;
  transition: color $duration $timing;
}
