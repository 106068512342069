.match-survey {
  @mixin answerButton {
    @include buttonUnstyled;
    width: 100%;
    text-align: left;
    font-weight: $light;
    color: $accentPrimary;
    border: 0 solid $neutral20;
    @include borderSize(border-width);
    padding: 0.08em 0.44em 0;
    transition: background-color $duration $timing,
    border-color $duration $timing;

    &:hover {
      background-color: $accentPrimaryLight;
      border-color: $accentPrimaryLight;
    }
  }

  // Can be Vue router link or regular button
  .next-button {
    @include buttonUnstyled;
    @include utilityPrimary;
    @include iconPost($arrowRightShort);
    font-size: 22px;
    color: $neutralWhite;
    background-color: $accentSecondary;
    padding: 0.545em 0.727em 0.24em;

    &::after {
      font-size: 21px;
      vertical-align: middle;
      margin-top: -5px;
      margin-left: 10px;
    }

    &.invisible {
      opacity: 0;
      visibility: hidden;
      width: 1px;
    }
  }

  .question {
    padding-bottom: 22px;

    .query {
      font-size: 34px;
      font-weight: $extrabold;
      color: $accentPrimary;
      padding-bottom: 0.471em;
    }

    .answers {
      @include listUnstyled;

      > li {
        + li {
          margin-top: 15px;
        }
      }

      .answer.active {
        .parent {
          color: $neutralWhite;
          border-color: $accentPrimary;
          background-color: $accentPrimary;
        }
      }

      .parent {
        @include answerButton;
        font-size: 25px;
      }

      .child-answers {
        @include listUnstyled;

        li:nth-child(1n+2) {
          .child:hover {
            border-top: 0 solid $accentPrimaryLight;
            @include borderSize(border-top-width);
            @include borderSize(margin-top, negative);
          }
        }

        li.active .child {
          color: $neutralWhite;
          border-color: $accentPrimary;
          background-color: $accentPrimary;
        }
      }

      .child {
        @include answerButton;
        font-size: 17px;
        padding: 0.471em 0.647em 0.175em;
        border-top: 0;
      }
    }

    // Wrapper around html <select>
    .answer-select {
      @include iconPost($caretDown);
      font-size: 25px;
      position: relative;
      display: inline-block;
      width: 100%;

      select {
        @include answerButton;
        width: 100%;
        border-radius: 0;
        appearance: none;
      }

      &::after {
        position: absolute;
        top: 50%;
        margin-top: -0.5em;
        right: 1em;
        color: $accentPrimary;
        font-size: 0.75em;
        pointer-events: none;
      }
    }
  }

  // Ul of secondary questions
  .sub-questions {
    @include listUnstyled;
    padding-top: 42px;
  }
}

.match-answer-content {
  @include headingSecondary;
  color: $accentPrimary;
  background-color: $neutral20;
  padding: 20px 14px 14px;

  .body {
    padding-bottom: 21px;
  }
}

.match-loader {
  display: block;
  text-align: center;

  img {
    width: 64px;
    height: auto;
    padding-top: 12px;
  }
}
