.sponsor-list {
  column-count: auto;
  column-fill: auto;

  @include respond($break75) {
    column-count: 2;
  }

  .heading-secondary {
    padding: 0 0 .25em;
  }

  .sponsor-type {
    break-inside: avoid;
  }

  span {
    @include linkPrimary;

    &:hover {
      background-image: none;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 1.5em;

    li {
      margin: 0 1em 0 0;
      padding: 0;

      + li {
        margin-top: 0;
      }

      &::before {
        display: none;
      }
    }
  }
}
