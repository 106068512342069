.content-block-secondary {
  .featured-image.narrow {
    width: 100%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    @include respond($break70) {
      display: none;
    }
  }

  header {
    padding: 38px 14px;
    color: $accentPrimary;

    .accent-secondary & {
      color: $accentSecondary;
    }

    datetime {
      @include fontSign;
      font-size: 15px;
    }
  }

  .wrapper {
    display: flex;

    .body {
      width: 100%;
      padding: 0 14px;

      &.image {
        @include respond($break70) {
          width: 54%;
          padding: 0 37px 0 14px;
        }
      }

      p:not(:has(img)) {
        max-width: 700px;
      }

      .back-link {
        margin-top: 89px;
      }
    }

    .featured-image {
      width: 56%;
      display: none;

      @include respond($break70) {
        display: block;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.content-news {

}
