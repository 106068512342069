.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 20px;
  background-color: $neutralWhite;
  transition: top $duration $timing,
    padding $durationFast $timing;

  &__inner {
    display: flex;
    align-items: center;
  }

  &.headroom--not-top {
    padding: 18px 0 12px;

    @include respond($break40) {
      padding: 20px 0 16px;
    }
  }

  &.headroom--unpinned {
    top: -100px;

    @include respond($break60) {
      top: 0;
    }
  }

  .logo, .logo-match {
    display: block;
    max-width: 142px;

    .mini {
      position: relative;
      display: none;
      max-width: 36px;
    }

    .narrow {
      @include respond($break70) {
        display: none;
      }
    }

    &.mini {
      .full {
        display: none;

        @include respond($break40) {
          display: block;
        }
      }

      .narrow {
        display: none;

        @include respond($break40) {
          display: block;
        }

        @include respond($break70) {
          display: none;
        }
      }

      .mini {
        display: block;

        @include respond($break40) {
          display: none;
        }
      }
    }

    @include respond($break40) {
      max-width: 166px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  // Overrides for match logo
  .logo-match {
    @include respond($break70) {
      max-width: 370px;
    };

    .full {
      display: none;

      @include respond($break70) {
        display: block;
      }
    }

    &.mini .full {
      display: none;

      @include respond($break70) {
        display: block;
      }
    }
  }


  .test-drive-link {
    display: none;
    position: absolute;
    top: -4px;
    left: calc(142px + 4.688vw);
    opacity: 0;
    visibility: hidden;
    transition: opacity $duration $timing,
    visibility $duration $timing;

    @include respond($break40) {
      display: block;
      top: -2px;
      left: calc(166px + 4.688vw);
    }

    @include respond($break90) {
      left: calc(166px + 45px);
    }

  }

  &.headroom--not-top .test-drive-link  {
    opacity: 1;
    visibility: visible;
  }

  .mini + .test-drive-link {
    opacity: 0;
    visibility: hidden;
  }

  .utility {
    top: -6px;

    @include respond($breakNav, 'max') {
      flex: 1 1 auto;
      text-align: right;
    };

    .search {
      position: absolute;
      top: -2px;
      right: 36px;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: opacity $duration $timing,
        visibility $duration $timing,
        right $duration $timing;


        display: flex;
        flex-wrap: nowrap;

      @include respond($break40) {
        top: -6px;
      };

      &.open {
        right: 0;
        opacity: 1;
        visibility: visible;
        transition: opacity $duration $timing 0.1s,
        visibility $duration $timing 0.1s,
        right $duration $timing 0.1s;
      }

      .search-form {
        display: inline-block;
        vertical-align: middle;
      }

      .search-close {
        @include iconPre($x);
        @include buttonUnstyled;
        font-size: 24px;
        color: $accentPrimary;
        margin-left: 8px;
        vertical-align: middle;

        @include respond($break40) {
          font-size: 28px;
          margin-left: 20px;
        }

        &::before {
          vertical-align: middle;
        }
      }
    }

    .buttons {
      transition: opacity $duration $timing 0.1s,
      visibility $duration $timing 0.1s,
      transform $duration $timing 0.1s;

      &.hidden {
        transform: translateX(36px);
        opacity: 0;
        visibility: hidden;
        transition: opacity $duration $timing,
        visibility $duration $timing,
        transform $duration $timing;
      }
    }

    .magnify {
      @include buttonUnstyled;
      @include iconPre($magnify);
      font-size: 23px;
      display: inline-block;

      @include respond($break40) {
        font-size: 27px;
      }
    }

    .hamburger {
      @include buttonUnstyled;
      @include iconPre($hamburger);
      font-size: 25px;
      margin-left: 13px;
      display: inline-block;

      @include respond($breakNav) {
        display: none;
      }
    }

    .magnify, .hamburger {
      @include buttonUnstyled;
      vertical-align: middle;
      line-height: 1;
      color: $accentPrimary;

      &::before {
        vertical-align: middle;
      }
    }
  }
}
