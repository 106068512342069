.icon-list {
  padding-top: 16px;

  figure {
    display: flex;
    flex-direction: column;

    @include respond($break40) {
      flex-direction: row;
    }
  }

  figure:not(:last-child) {
    padding-bottom: 12px;
  }

  figcaption {
    padding: 25px 0;

    h2 {
      text-align: center;
    }

    @include respond($break40) {
      padding: 25px 0 25px 20px;

      h2 {
        text-align: left;
      }
    }
  }

  .icon {
    margin: 0 auto;
    display: block;
    width: 104px;
    height: 104px;

    @include respond($break40) {
      margin: 0;
      width: auto;
    }
  }

  .icon-heading {
    font-size: 30px;
    font-weight: $extrabold;
    color: $accentSecondary;
    text-transform: uppercase;
  }
}
