.sign-up {
  padding: 38px 0;

  // Just a couple little overrides
  @include respond($break40) {
    padding: 57px 0;
  }

  .content-default h3 {
    padding: 0;
  }
}

.site-footer {
  padding: 45px 0 40px;

  @include respond($break40) {
    padding: 60px 0 45px;

  }

  .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond($break40) {
      flex-wrap: nowrap;
    };

    .description {
      order: 1;
      color: $accentPrimary;
      font-size: 16px;

      @include respond($break40) {
        order: 3;
        max-width: 520px;
        padding-left: 50px;
        font-size: 18px;
      }
    }

    .footer-nav-primary {
      order: 2;
      padding-top: 22px;
      padding-bottom: 60px;
      white-space: nowrap;

      @include respond($break40) {
        order: 1;
      }

      ul {
        @include listUnstyled;

        a {
          display: block;
          padding-bottom: 1px;
          font-size: 16px;
          color: $accentPrimary;
          border-bottom: 4px solid $accentPrimary;
        }

        li + li a {
          padding-top: 8px;
        }
      }
    }

    .test-drive-link {
      order: 4;
      margin-top: 11px;

      @include respond($break40) {
        display: none;
      }
    }
  }


  .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .colophon {
      padding-top: 8px;
      color: $accentPrimary;
      font-size: 12px;
      order: 2;

      @include respond($break65) {
        padding-top: 15px;
        padding-right: 50px;
        order: 1;
      }
    }

    .footer-nav-secondary {
      order: 1;
      width: 100%;

      @include respond($break65) {
        order: 2;
        width: auto;
      }

      .social, .logo {
        display: inline-block;
        vertical-align: top;
      }

      .social li {
        display: inline-block;
      }

      .social li a {
        font-size: 30px;
        color: $accentPrimary;
      }

      .logo {
        width: 100%;
        max-width: 153px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
