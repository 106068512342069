.hero .marquee {
  position: relative;
  min-height: 100px;

  @include respond($break80) {
    min-height: 130px;
  }

  .marquee-dynamic {
    position: absolute;
    display: block;

    &.marquee-leave-active {
      position: absolute;
      max-width: 100%;
      transform: translateY(-32px) rotateX(45deg) perspective(600px);
      opacity: 0;
      visibility: hidden;
      transition: transform $duration $timing,
        opacity $duration $timing 0.05s,
        visibility $duration $timing 0.05s;
    }

    &.marquee-enter {
      transform: translateY(42px) rotateX(-45deg) perspective(600px);
      opacity: 0;
    }
    //
    //.marquee-enter-to {
    //  opacity: 1;
    //  visibility: visible;
    //}
    //
    &.marquee-enter-active {
      transition: transform $durationSlow $timing 0.2s,
        opacity $duration $timing 0.2s;
    }
  }

}