.pagination {
  padding-top: 52px;
  ul {
    @include listUnstyled;

    li {
      display: inline-block;

      + li {
        margin-left: 0;
      }
    }

    a {
      @include fontSign;
      display: block;
      color: $accentPrimary;
      font-size: 36px;
      border: 0 solid transparent;
      @include borderSize(border-bottom-width);
      padding: 0 0.3em;
      transform: border-color $duration $timing;
      line-height: 0.25em;

      &.active, &:hover {
        border-color: $accentPrimaryLight;
      }
    }

    .pager-left, .pager-right {
      display: inline-block;
      color: $neutralWhite;
      vertical-align: baseline;
      font-size: 28px;
      border: 0;

      &::before {
        background-color: $accentPrimary;
        padding: 2px 4px;
      }
    }

    .pager-left {
      @include iconPre($arrowLeft);
      margin-right: 18px;
    }

    .pager-right {
      @include iconPre($arrowRight);
      margin-left: 18px;
    }
  }
}