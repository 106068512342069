@use "sass:math";

.m-modal {
  $_toggle-icon-size: 24px;

  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 10vw);
  max-width: 800px;
  max-height: calc(100% - 10vw);
  background-color: $neutralWhite;
  box-shadow: 5px 15px 35px 8px rgba(0, 0, 0, 0.13);

  &.is-open {
    display: block;
  }

  &__text-container {
    padding: 30px;
  }

  &__toggle {
    @include buttonUnstyled;
    position: absolute;
    top: math.div($_toggle-icon-size, -2);
    right: math.div($_toggle-icon-size, -2);
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $accentPrimary;
    background-color: $neutralWhite;
    border-radius: 50%;
  box-shadow: 5px 15px 35px 8px rgba(0, 0, 0, 0.13);
  }

  &__toggle-icon {
    fill: currentcolor;
    width: 20px;
    height: 20px;
  }

  &__border-top {
    @include borderSize(height);
    background-color: $accentPrimary;
  }

  &__countdown {
    background-color: $accentPrimaryDark;
    color: #fff;
    padding: .75rem 2rem;
    font-weight: 700;
    text-align: right;
    font-size: 1rem;

    @include respond($break60) {
      font-size: 1.25rem;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

body {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -25;
    width: 100%;
    height: 100%;
    content: '';
  }

  &.modal-is-open {
    position: absolute;
    width: 100%;
    overflow: hidden;

    &::after {
      z-index: 10000;
      background-color: rgba(0, 0, 0, 0.62);
    }
  }
}
