// Animating border container
.border-container {
  width: 100%;
  position: relative;
  z-index: 5;

  &.off-screen {
    opacity: 0;

    .a-border-top, .a-border-bottom {
      opacity: 0;
      max-width: 0;
    }

    .a-overhang {
      opacity: 0;
      max-height: 0;
    }
  }

  &.on-screen {
    opacity: 1;

    .a-border-top, .a-border-bottom {
      opacity: 1;
      max-width: 100%;
    }

    .a-overhang {
      opacity: 1;
      max-height: 123px;
    }
  }
}

.a-border-top, .a-border-bottom {
  @include borderSize(height);
  position: relative;
  width: 100%;
  max-width: 0;
  background-color: $accentPrimary;
  z-index: 5;
  transition: max-width $durationSlow linear,
  opacity $durationSlow $timing;

  .accent-secondary & {
    background-color: $accentSecondary;
  }

  .left & {
    float: right;
  }
}

.a-border-bottom {
  &.off-screen {
    opacity: 0;
    max-width: 0;
  }

  &.on-screen {
    opacity: 1;
    max-width: 960px;
  }
}

.a-overhang {
  position: absolute;
  top: 0;
  right: 0;
  @include borderSize(width);
  z-index: 5;
  // Height is overridden by JS if header exists
  height: 123px;
  max-height: 0;
  background-color: $accentPrimary;
  transition: max-width $duration linear,
  opacity $duration $timing;
  transition-delay: $durationSlow;

  .accent-secondary & {
    background-color: $accentSecondary;
  }

  .left & {
    right: auto;
    left: 0;
  }
}