// Nav wrapping a ul. Likely not used on its own without a wrapper
.social {
  ul {
    @include listUnstyled;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
  }

  .facebook {
    @include iconPre($facebook);
  }

  .youtube {
    @include iconPre($youtube);
  }

  .linked-in {
    @include iconPre($linkedIn);
  }
}
