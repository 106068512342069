// Component Structure
// -------------------

// Structure-only mixins (and maybe utility classes), with parameters for margins, padding, position, display, etc.

// They may contain:
// Nested classes/elements that have structural parameters only

// NB: Containers that are modular regardless of context can use em units
// Structure should not be dependent on typography styles but font-size CAN be used as a structural style if necessary
// for scaling

// Utility Classes
// --------------------------------------------------------
.rel {
  position: relative;
}

.cf {
  @include clearfix;
}

.oh {
  overflow: hidden;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-left {
  text-align: right;
}

// Containers
// --------------------------------------------------------
.container {
  width: 100%;
  max-width: $containerWidthMax;
  padding-right: $containerPadding;
  padding-left: $containerPadding;
  margin-right: auto;
  margin-left: auto;

  @include respond($break90) {
    padding-right: $containerPaddingMax;
    padding-left: $containerPaddingMax;
  }

  &.narrow {
    max-width: 575px;
  }

  &.full {
    max-width: none;
  }
}

.section-spacing  {
  padding-top: 65px;
  padding-bottom: 58px;

  @include respond($break90) {
    padding-top: 91px;
    padding-bottom: 88px;
  }

  &.small {
    @include respond($break60) {
      padding-top: 55px;
      padding-bottom: 50px;
    }
  }
}

.section-spacing-top {
  padding-top: 80px;

  @include respond($break60) {
    padding-top: 120px;
  }

  @include respond($break90) {
    padding-top: 150px;
  }

  &.small {
    @include respond($break40) {
      padding-top: 60px;
    }
  }
}

.section-spacing-bottom {
  padding-bottom: 80px;

  @include respond($break60) {
    padding-bottom: 120px;
  }

  @include respond($break90) {
    padding-bottom: 150px;
  }

  &.small {
    @include respond($break40) {
      padding-bottom: 60px;
    }
  }
}

// Bumps
// --------------------------
.header-bump {
  height: 94px;
}

.no-hero-bump {
  height: 46px;
}
