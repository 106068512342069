.header-tablet {
  background-color: $neutralWhite;
  position: fixed;
  z-index: 6;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 18px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    display: block;
    max-width: 345px;

    @include respond($break100) {
      max-width: 395px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
