.article-list-image {
  display: flex;
  flex-wrap: wrap;

  @include respond($break40) {
    margin-left: -28px;
  }

  .article {
    color: $neutralOffBlack;
    display: block;
    width: 100%;
    margin-bottom: 65px;

    @include respond($break40) {
      width: 50%;
      margin-bottom: 50px;
      padding-left: 28px;
    }

    @include respond($break75) {
      margin-bottom: 86px;
      width: 33.333%;
    }

    &:hover {
      .border-top, header:before, header:after {
        background-color: $accentSecondary;

        .accent-secondary & {
          background-color: $accentPrimary;
        }
      }

      header, .more-link, .linked-in-link::before, .mail-link::before {
        color: $accentSecondary;

        .accent-secondary & {
          color: $accentPrimary;
        }
      }
    }

    header, .summary {
      padding-right: 19px;
      padding-left: 19px;
    }

    header {
      position: relative;
      padding-top: 25px;
      color: $accentPrimary;
      transition: color $duration $timing;
      padding-bottom: 18px;

      .accent-secondary & {
        color: $accentSecondary;
      }

      datetime {
        font-size: 16px;
      }

      &::before, &::after {
        background-color: $accentPrimary;

        .accent-secondary & {
          background-color: $accentSecondary;
        }

        transition: background-color $duration $timing;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        @include borderSize(height);
        content: '';
      }

      &::after {
        position: absolute;
        @include borderSize(width);
        height: 35px;
        top: 0;
        left: 0;
        content: '';
      }

      .heading-tertiary.all-caps {
        text-transform: uppercase;
      }
    }
  }

  .content-default {
    margin-bottom: 18px;
  }

  .more-link {
    @include linkPrimary;

    &:hover {
      background: none; // OD
    }
  }

  .border-top {
    display: block;
    width: 100%;
    @include borderSize(height);
    background-color: $accentPrimary;

    .accent-secondary & {
      background-color: $accentSecondary;
    }
  }

  figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 67.677%;
    background-size: cover;
    background-position: 50% 50%;
  }
}

.portrait-mode .article-list-image {
  figure {
    padding-top: 114%;
  }

  header {
    display: flex;
    wrap: nowrap;
    padding-right: 0;

    .left-side {
      flex-grow: 10;
    }

    .right-side {
      padding-left: 15px;
    }
  }
}
