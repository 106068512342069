.reservation {
  @mixin reservationHeading {
    font-size: 37px;
    line-height: 1;
    font-weight: $extrabold;

    @include respond($break70) {
      font-size: 43px;
    }
  }

  display: flex;
  flex-wrap: wrap;
  margin-left: -7vw;
  width: calc(100% + 7vw);

  @include respond($break80) {
    flex-wrap: nowrap;
  }

  @include respond($break100) {
    margin-left: -110px;
    width: calc(100% + 110px);
  }

  .col {
    padding-left: 7vw;

    @include respond($break60) {
      width: auto;
    }

    @include respond($break100) {
      padding-left: 110px;

      &.info {
        padding-left: 75px;
      }
    }
  }

  .info {
    .heading {
      @include reservationHeading;
      margin-bottom: 21px;

      &::after {
        @include borderSize(height);
        display: block;
        background-color: $accentPrimary;
        width: 42px;
        content: '';
      }
    }

    p {
      font-size: 16px;
      font-weight: $light;

      @include respond($break40) {
        font-size: 18px;
      }
    }

    .notices {
      margin-bottom: 1.4em;

      p + p {
        margin-top: 0;
      }
    }
  }

  .link {
    display: flex;
    flex-grow: 2;
    width: 100%;
    margin-top: 32px;
    align-items: center;

    @include respond($break60) {
      width: auto;
      margin: 0 0 32px;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px 20px 10px 17px;
      @include reservationHeading;
      color: $accentPrimary;
      border: 0 solid $neutralWhite;
      @include borderSize(border-top-width);
      @include borderSize(border-bottom-width);
      @include borderSize(border-left-width);

      @include respond($break70) {
        font-size: 37px;
      }

      .link-container {
        display: flex;
        align-items: flex-end;
      }

      &::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        @include borderSize(width);
        height: 44.335%;
        background-color: $neutralWhite;
        content: '';
      }

      .arrow-right {
        @include iconPost($arrowRight);

        &::after {
          font-size: 0.8em;
          line-height: 1;
          padding: 4px;
          margin-left: 1.286em;
          color: $neutralWhite;
          background-color: $accentPrimaryLight;
        }
      }
    }
  }
}