.article-list-block {
  ul {
    @include listUnstyled;
    display: flex;
    margin-left: -25px;
    flex-wrap: wrap;

    li {
      display: flex;
      padding-left: 25px;
      width: 100%;

      @include respond($break60) {
        width: 33.333%;
      }

      + li {
        padding-top: 25px;

        @include respond($break60) {
          padding-top: 0;
        }
      }
    }

    a {
      position: relative;
      border: 0 solid $accentPrimaryLight;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding: 23px 17px;
      @include borderSize(border-top-width);
      @include borderSize(border-bottom-width);
      @include borderSize(border-left-width);
      transition: border-color $duration $timing;

      .bg-accent-light & {
        border-color: $neutralWhite;
      }

      &:hover {
        border-color: $accentPrimary;

        .title {
          color: $accentPrimary;
        }

        .bg-accent-light & {
          &::after {
            background-color: $accentPrimary;
          }
        }
      }

      &::after {
        @include borderSize(width);
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $accentPrimaryLight;
        height: 44.44%;
        content: '';
        transition: background-color $duration $timing;

        .bg-accent-light & {
          background-color: $neutralWhite;
        }
      }
    }

    .label {
      @include fontSign;
      position: relative;
      padding-bottom: 4px;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-size: 15px;
      color: $accentPrimary;

      &::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $accentPrimary;
        width: 24px;
        height: 6px;
        content: '';
      }
    }

    .title {
      @include headingQuaternary;
      color: $accentPrimary;
      transition: color $duration $timing;

      .bg-accent-light & {
        color: $neutralWhite;
      }
    }

    .subtitle {
      padding-top: 14px;
      color: $neutralOffBlack;

      .bg-accent-light & {
        color: $accentPrimary;
      }
    }
  }
}
