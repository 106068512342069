.gdpr-banner {
    width: 100%;
    color: $neutralOffBlack;
    background-color: $neutralWhite;
    box-shadow: 0px 0px 15px 0px rgba($neutralOffBlack, 0.2);
  
    &__button {
      text-decoration: none;
      font-weight: 500;
  
      &--opt-out {
        text-transform: uppercase;
        background-color: $neutral20;
      }
  
      &--opt-in {
        color: $neutralOffBlack;
        text-transform: uppercase;
        background-color: $accentPrimaryLight;
      }
  
      &--dismiss {
        color: $neutralOffBlack;
      }
    }
  }
