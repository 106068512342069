.match-results {
  .vehicle-list {
    @include listUnstyled;
    padding-bottom: 32px;

    header {

      h2 {
        padding-bottom: 0.743em;
        font-size: 28px;
        font-weight: $light;
        color: $accentPrimary;

        @include respond($break40) {
          font-size: 35px;
        }
      }
    }
  }

  .vehicle {
    position: relative;

    + .vehicle {
      margin-top: 27px;

      @include respond($break70) {
        margin-top: 42px;
      }
    }

    figure.narrow {
      width: 100%;

      @include respond($break70) {
        display: none;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    .a-border-top, .a-overhang {
      background-color: $accentPrimaryLight;
    }

    .wrapper {
      position: relative;
      padding: 23px 18px 23px 0;

      @include respond($break60) {
        padding: 41px 39px;
      }
    }

    .circle-link {
      display: none;
      position: absolute;
      background-color: $accentSecondary;
      right: 18px;
      width: 92px;
      height: 92px;

      @include respond($break60) {
        right: 39px;
      }

      @include respond($break40) {
        width: 112px; // OD
        height: 112px; // OD
      }

      @include respond($break70) {
        display: table;
      }

      &.narrow {
        top: 17px;
        display: table;

        @include respond($break70) {
          display: none;
        }
      }
    }

    .body {
      display: flex;

      .stats {
        width: 100%;

        &.image {
          width: 100%;

          @include respond($break70) {
            width: 50%;
            padding-right: 21px;
          }

          @include respond($break100) {
            width: calc(100% - 450px);
            padding-right: 30px;
          }
        }
      }

      figure {
        display: none;
        width: 50%;

        @include respond($break70) {
          display: block;
        }

        @include respond($break100) {
          width: 450px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .stats {
      @include listUnstyled;
      font-weight: $light;
      color: $accentPrimary;

      strong {
        @include fontSign;
      }

      li {
        + li {
          margin-top: 2px;
        }
      }

      a {
        @include linkPrimary;
        @include fontBase;
        font-weight: $light;
        color: $accentSecondary;
        word-break: break-all;
      }
    }
  }
}
