.circle-link-nav {
  max-width: 490px;
  margin: 0 auto;

  ul {
    @include listUnstyled;
    display: flex;
    justify-content: space-around;
  }

  li {
    display: flex;

    // + li {
    //   margin-left: 30px;
    // }

    a {
      @include fontSign;
      display: flex;
      align-items: center;
      width: 75px;
      height: 75px;
      font-size: 15px;
      line-height: 1;
      text-align: center;
      background-color: $accentPrimary;
      color: $neutralWhite;
      border-radius: 100%;

      @include respond($break40) {
        width: 110px;
        height: 110px;
        font-size: 22px;
      }
    }
  }
}
