// Default form styles
form {
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    font-size: 16px;
    border: 1px solid $neutral20;
    outline: 0;
    resize: vertical;
    padding: 5px 7px 2px;
    transition: border-color $duration $timing;

    &:focus {
      padding-bottom: 0px;
      border-bottom-width: 3px;
      border-bottom-color: $accentPrimaryLight;

      .bg-accent-light & {
        border-bottom-color: $accentPrimary;
      }
    }

    &::placeholder {
      color: $accentPrimary;
    }
  }

  .form-group {
    + .form-group {
      margin-top: 48px;
    }

    + button[type="submit"] {
      margin-top: 32px;
    }

    .heading-secondary {
      padding-bottom: 0.68em;
    }
  }


  .form-input + .form-input,
  .form-input + .form-flex,
  .form-flex + .form-flex,
  .form-flex + .form-input {
    padding-top: 8px;
  }

  // NOT A FLEXFORM
  .form-flex {
    display: flex;
    margin-left: -10px;

    @include respond($break65) {
      margin-left: -20px;
    }

    &.half {
      @include respond($break80) {
        max-width: calc(50% + 10px);
      }
    }

    &.cc {
      justify-content: space-between;
      flex-wrap: wrap;

      @include respond($break40) {
        flex-wrap: nowrap;
      }

      .number {
        flex-grow: 2;
      }

      .date {
        display: flex;
        min-width: 100%;
        padding-left: 10px;
        padding-top: 8px;

        @include respond($break40) {
          min-width: 0;
          padding-top: 0;
        }

        @include respond($break65) {
          padding-left: 20px;
        }
      }
    }

    .form-input {
      padding-left: 10px;

      @include respond($break65) {
        padding-left: 20px;
      }
    }

    .form-input + .form-input {
      padding-top: 0;
    }

    .grow-half {
      min-width: 50%;
      max-width: 50%;

      &.resp {
        min-width: 100%;
        max-width: 100%;

        @include respond($break50) {
          min-width: 50%;
          max-width: 50%;
        }
      }
    }

    .grow {
      flex-grow: 1;
    }
  }

  textarea {
    height: 124px;
  }

  label {
    @include fontSign;
    color: $accentPrimary;

    &.inline {
      @include fontBase;
      padding: 5px 0 2px;

      + .form-input {
        padding-left: 7px;
      }
    }
  }

  input[type="submit"], button[type="submit"] {
    @include buttonUnstyled;
    @include utilityTertiary;
    margin-top: 12px;
    position: relative;
    color: $accentPrimary;
    min-width: 120px;
    border: 0 solid $accentPrimary;
    @include borderSize(border-top-width);
    @include borderSize(border-left-width);
    @include borderSize(border-bottom-width);
    padding: 3px 10px 0;

    // Note, will not work on inputs
    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      height: 54.286%;
      background-color: $accentPrimary;
      @include borderSize(width);
      content: '';
    }
  }

  .checkbox {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    font-size: 16px;
    color: $accentPrimary;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;

      &:checked ~ .toggle-indicator::before {
        opacity: 1;
        margin-top: -4px;
      }
    }

    .toggle-indicator {
      @include iconPre($check);
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: $neutralWhite;
      border: 2px solid $neutral20;
      font-size: 19px;
      user-select: none;

      &::before {
        display: block;
        font-size: 19px;
        margin-top: -14px;
        padding: 0 0 2px;
        opacity: 0;
        transition: opacity $durationFast $timing,
          margin $duration $timing;
      }
    }
    &.survey {
      @include fontBase;
      font-size: 18px;
      a {
        @include linkPrimary;
      }
    }
  }

  // Always a .form-input, contans a <select>
  .dropdown {
    @include iconPost($caretDown);
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      right: 5px;
      margin-top: -4px;
      font-size: 8px;
      color: $accentPrimary;
      pointer-events: none;
    }

    select {
      width: 100%;
      font-size: 16px;
      border: 1px solid $neutral20;
      outline: 0;
      resize: vertical;
      padding: 5px 30px 2px 7px;
      appearance: none;
      color: $accentPrimary;
      background-color: $neutralWhite;
      border-radius: 0;
      cursor: pointer;

      &::-ms-expand {
        display: none;
      }

      &:focus:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $accentPrimary;
      }
    }
  }
}


.form-errors {
  font-size: 15px;
  padding-left: 14px;
  margin-top: 0;
  margin-bottom: 12px;
  color: $accentSecondaryDark;

  .heading-secondary + & {
    padding-top: 8px;
  }

  + form {
    padding-top: 12px;
  }
}

.form-card {
  position: relative;
  width: 100%;
  padding: 14px 19px 14px 14px;
  border-color: $neutral20;
  border-style: solid;
  @include borderSize(border-top-width);
  @include borderSize(border-left-width);
  @include borderSize(border-bottom-width);
  border-right-width: 0;

  @include respond($break50) {
    padding: 18px 27px 20px 20px;
  }

  @include respond($break75) {
    width: calc(50% - 10px);
  }


  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 60%;
    @include borderSize(width);
    background-color: $neutral20;
    content: '';
  }

  header {
    padding-bottom: 13px;
  }

  .label {
    @include fontSign;
    position: relative;
    padding-bottom: 4px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 15px;
    color: $accentPrimary;

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $accentPrimary;
      width: 24px;
      height: 6px;
      content: '';
    }
  }
}

.donate-form {
  input[type='submit'] {
    padding-left: 45px;
    padding-right: 45px;
    border-width: 4px;
    background-color: $accentPrimary;
    color: $neutralWhite;
  }
}

.sign-up {
  .checkbox {
    color: $neutralWhite;
    margin-right: 28px;

    .toggle-indicator {
      background-color: $accentPrimary;
      border-color: $neutralWhite;
    }
  }
}

.contact-form {
  .form-error {
    border-bottom: 3px solid $accentSecondary;
  }
}

