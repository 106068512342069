.event-filters {
  $gridGap: 10px;

  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto $gridGap auto $gridGap auto;
  -ms-grid-columns: 100%;
  grid-template:
    "caption" auto
    "options" auto
    "submit" auto / 100%;
  grid-gap: $gridGap;
  align-items: baseline;
  margin-bottom: 45px;
  padding: 1.25em 2.5em 1.5em;
  border: 0 solid $accentPrimaryLight;
  @include borderSize(border-top-width);
  @include borderSize(border-right-width);

  @include respond($break50) {
    -ms-grid-rows: auto $gridGap auto;
    -ms-grid-columns: 1fr $gridGap auto;
    grid-template:
      "caption submit" auto
      "options options" auto / 1fr auto;
  }

  &::before, &::after {
    position: absolute;
    background-color: $accentPrimaryLight;
    display: block;
    content: '';
  }

  &::before {
    top: 0;
    left: 0;
    @include borderSize(width);
    height: 67.544%;
  }

  &::after {
    bottom: 0;
    right: 0;
    width: 63%;
    height: 7px;
    @include borderSize(height);
  }

  &__caption {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: caption;
    @include headingTertiary;
    margin-right: 10px;
    color: $accentPrimary;
    text-transform: uppercase;

    @include respond($break50) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
  }

  &__options {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: options;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-left: -1em;

    @include respond($break50) {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
    }
  }

  &__option {
    position: relative;
    margin: 0.5em 0.5em 0;
    cursor: pointer;
  }

  &__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  &__text {
    padding: 0 0.5em;
    @include fontSign;
    color: $accentPrimary;
    border: 0 solid transparent;
    @include borderSize(border-bottom-width);
    line-height: 0.25em;
    transform: border-color $duration $timing;

    .event-filters__input:checked + &,
    .event-filters__option:hover & {
      border-color: $accentPrimaryLight;
    }
  }

  &__submit {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    grid-area: submit;
    margin: 0 !important;

    @include respond($break50) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
      -ms-grid-column-align: end;
      justify-self: end;
    }
  }
}
