.slider-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  margin-top: 10px;
}

.slider {
  overflow-x: hidden;

  ul {
    @include listUnstyled;
    display: flex;
    justify-content: flex-start;
    transition: $timing transform $durationSlow;

    li {
      position: static;
      display: block;
      list-style-type: none;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;

      &::before {
        display: none;
        content: none;
      }

      + li {
        margin-top: 0;
      }

      .slide {
        position: relative;
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        .caption {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          color: $neutralOffBlack;
          font-weight: $light;
        }
      }
    }
  }

  .slider-nav {
    .left, .right {
      $maxFont: 60px;
      position: absolute;
      top: 0;
      width: auto;
      height: 100%;
      cursor: pointer;

      &::before, &::after {
        position: relative;
        top: calc(50% - (#{$maxFont} / 2));
        font-size: $maxFont;
        color: $accentPrimary;
        cursor: pointer;
      }
    }

    .left {
      @include iconPre($caretLeft);
      left: 0;
    }

    .right {
      @include iconPost($caretRight);
      right: 0;
    }
  }
}

.col {
  .slider-container {
    width: calc(100vw - #{$containerPadding} - #{$containerPadding} - 32px);

    @include respond($break60) {
      width: calc(100vw - #{$containerPadding} - #{$containerPadding} - 12px - 25px - 13px);
    }

    @include respond($break65) {
      width: calc(((100vw - #{$containerPadding} - #{$containerPadding}) / 2) - 6.5vw);
    }

    @include respond($break90) {
      width: calc(((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 5.5vw);
    }

    @include respond($break100) {
      width: calc(((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 62px);
    }

    @include respond($break110) {
      width: calc(((#{$containerWidth}) / 2) - 62px);
    }

    .slider {
      width: calc(0.7 * (100vw - #{$containerPadding} - #{$containerPadding} - 12px));
      height: calc(0.6 * (100vw - #{$containerPadding} - #{$containerPadding} - 12px));

      @include respond($break60) {
        width: calc(0.7 * (100vw - #{$containerPadding} - #{$containerPadding} - 12px));
        height: calc(0.6 * (100vw - #{$containerPadding} - #{$containerPadding} - 12px));
      }

      @include respond($break65) {
        width: calc(0.7 * (((100vw - #{$containerPadding} - #{$containerPadding}) / 2) - 6.5vw));
        height: calc(0.6 * (((100vw - #{$containerPadding} - #{$containerPadding}) / 2) - 6.5vw));
      }

      @include respond($break90) {
        width: calc(0.7 * (((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 5.5vw));
        height: calc(0.6 * (((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 5.5vw));
      }

      @include respond($break100) {
        width: calc(0.7 * (((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 62px));
        height: calc(0.6 * (((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 62px));
      }

      @include respond($break110) {
        width: calc(0.7 * (((#{$containerWidth}) / 2) - 62px));
        height: calc(0.6 * (((#{$containerWidth}) / 2) - 62px));
      }

      .slide {
        width: calc(0.7 * (100vw - #{$containerPadding} - #{$containerPadding} - 12px));
        height: calc(0.6 * (100vw - #{$containerPadding} - #{$containerPadding} - 12px));

        @include respond($break60) {
          width: calc(0.7 * (100vw - #{$containerPadding} - #{$containerPadding} - 12px));
          height: calc(0.6 * (100vw - #{$containerPadding} - #{$containerPadding} - 12px));
        }

        @include respond($break65) {
          width: calc(0.7 * (((100vw - #{$containerPadding} - #{$containerPadding}) / 2) - 6.5vw));
          height: calc(0.6 * (((100vw - #{$containerPadding} - #{$containerPadding}) / 2) - 6.5vw));
        }

        @include respond($break90) {
          width: calc(0.7 * (((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 5.5vw));
          height: calc(0.6 * (((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 5.5vw));
        }

        @include respond($break100) {
          width: calc(0.7 * (((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 62px));
          height: calc(0.6 * (((100vw - #{$containerPaddingMax} - #{$containerPaddingMax}) / 2) - 62px));
        }

        @include respond($break110) {
          width: calc(0.7 * (((#{$containerWidth}) / 2) - 62px));
          height: calc(0.6 * (((#{$containerWidth}) / 2) - 62px));
        }
      }
    }
  }
}
