.event-list {
  $gridGap: 20px;

  @include listUnstyled;

  &__item {}

  &__inner {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto $gridGap auto $gridGap auto;
    -ms-grid-columns: auto;
    grid-template:
      "image" auto
      "header" auto
      "time" auto / 100%;
    -ms-flex-align: baseline;
    align-items: baseline;
    grid-gap: $gridGap;
    padding-top: $gridGap;
    padding-bottom: $gridGap * 1.5;
    border-top: 1px solid $accentPrimary;

    @include respond($break40) {
      -ms-grid-rows: auto $gridGap auto;
      -ms-grid-columns: auto $gridGap 1fr;
      grid-template:
        "image header" auto
        "image time" auto / auto 1fr;
    }

    @include respond($break75) {
      -ms-grid-rows: auto;
      -ms-grid-columns: auto $gridGap 1fr $gridGap 147px;
      grid-template: "image header time" auto / auto 1fr 147px;
    }
  }

  &__header {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: header;
    display: flex;
    flex-direction: column;
    padding-top: 3px;

    @include respond($break40) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
  }

  &__title {
    order: 1;
    color: $accentPrimary;
  }

  &__title-inner {
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 7px 7px;
    transition: background-image $duration $timing;

    .event-list__link:hover & {
      background-image: linear-gradient($accentPrimaryLight, $accentPrimaryLight);
    }
  }

  &__desc {
    order: 1;
    margin-top: 12px;
  }

  &__categories {
    @include fontSign;
    position: relative;
    padding-bottom: 4px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 15px;
    color: $accentPrimary;

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $accentPrimary;
      width: 24px;
      height: 6px;
      content: '';
    }
  }

  &__time {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    grid-area: time;
    color: $accentPrimary;

    @include respond($break40) {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }

    @include respond($break75) {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }
  }

  &__image {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: image;
    -ms-grid-row-align: start;
    align-self: start;
    display: block;
    width: 175px;
    height: auto;
    flex-shrink: 0;

    @include respond($break40) {
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;
      -ms-grid-column: 1;
    }

    @include respond($break75) {
      -ms-grid-row-span: 1;
    }
  }

  &__link {
    color: inherit;
  }
}
