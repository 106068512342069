.newsletter-form {
 // Basic form styles for newsletter
  padding-top: 18px;

  @include respond($break40) {
    padding-top: 11px;
  }

  input[type="text"], input[type="email"] {
    width: 100%;
    padding: 6px 10px 1px;
    margin-right: 0px;
    border: 0;
    outline: 0;
    border-bottom: 3px solid $neutralWhite;
    color: $neutralOffBlack;
    font-size: 15px;
    transition: border-color $duration $timing;

    @include respond($break40) {
      width: auto;
    }

    &:focus {
      border-color: $accentPrimaryLight;
    }

    &::placeholder {
      color: $accentPrimary;
    }

    + input {
      margin-top: 7px;

      @include respond($break40) {
        margin-top: 0;
      }
    }

    &.form-error {
      border-bottom-color: $accentSecondary;
    }
  }

  .checkbox-error {
    font-style: italic;
    font-weight: $semibold;
  }

  .submit {
    @include iconPre($arrowRight);
    @include buttonUnstyled;
    display: block;
    min-width: 0; // OD
    background-color: $accentPrimaryLight;
    border: 0; // OD
    margin-top: 7px;

    @include respond($break40) {
      display: inline-block;
      padding: 0;
    }

    &::after {
      display: none; // OD
    }

    &::before {
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;
      padding: 3px;
    }
  }

  // Mailchimp specific classes
  #mc_embed_signup input.mce_inline_error {
    border-color: $error;
  }
  #mc_embed_signup div.mce_inline_error {
    margin-top: 12px;
    padding: 0;
    font-size: 15px;
    font-weight: $semibold;
    letter-spacing: 0.1em;
    color: $error;
    background-color: transparent;
  }

  .response {
    margin-top: 12px;
    font-size: 15px;
    font-weight: $semibold;
  }
}
