// Base mixins
// ---------

// Clearfix
// $mode accepts 'light' or 'heavy'
// 'light' is sufficient for clearing floats, while 'heavy' is required to
// prevent margin collapsing
// --------------------------
@mixin clearfix($mode: light) {
  @if $mode == heavy {
    &::before,
    &::after {
      display: table;
      content: ' ';
    }

    &::after {
      clear: both;
    }
  } @else {
    &::after {
      display: block;
      clear: both;
      content: ' ';
    }
  }
}

.clearfix {
  @include clearfix;
}

// Kill ye FOUT
// Depends on Typekit's webfontloader adding the .wf-active class...
// --------------------------------------------------------
@mixin killFout {
  visibility: hidden;

  .wf-active &, // Show when fonts become active
  .no-js &, // Show if no javascript
  // scss-lint:disable SelectorFormat
  .mceContentBody & { // Show if this is the RTE ;)
    visibility: visible;
  }
}

@mixin thinGlyphs {
  // Ignore spelling and prefixes for pre-standards parameter
  // scss-lint:disable PropertySpelling
  font-smooth: always;
  // scss-lint:disable VendorPrefix
  -webkit-font-smoothing: antialiased;
  // scss-lint:disable VendorPrefix
  -moz-osx-font-smoothing: grayscale;
}

// Lists
// --------------------------------------------------------
// Unstyled List
// --------------------------
@mixin listUnstyled {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

// Horizontal List
// --------------------------
@mixin listHorizontal {
  @include listUnstyled;
  li {
    display: inline-block;
  }
}

// Links, Buttons
// --------------------------------------------------------
@mixin buttonUnstyled {
  appearance: none;
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
}

// Prevents user from accidentally selecting text
// on click, helpful for labels
@mixin noSelect {
  // Ignore vendor prefixing as this is a pre-standards parameter
  // scss-lint:disable VendorPrefix
  -webkit-user-select: none;
  // scss-lint:disable VendorPrefix
  -moz-user-select: none;
  // scss-lint:disable VendorPrefix
  -ms-user-select: none;
}
