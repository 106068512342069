.content-block-outlined {
  position: relative;
  border: 0 solid $accentPrimaryLight;
  @include borderSize(border-top-width);
  @include borderSize(border-right-width);
  @include borderSize(border-left-width);
  padding: 0 12px 22px;

  @include respond($break60) {
    padding: 0 19px 22px;
  }

  &::after {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 74.216%;
    @include borderSize(height);
    @include borderSize(margin-right, negative);
    background-color: $accentPrimaryLight;
    content: '';
  }
}