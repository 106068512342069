// Component Typography and Prototypes
// -----------------------------------

// Template typography mixins/prototypes and utility classes that can be extended into content classes.
// Typographic classes can contain some structural (not textural) design where it does not require abstraction,
// and textural classes dealing with typography (color, text-decoration, pseudo elements that modify text, etc).

// They may contain:
// Nested classes or elements with typographic/basic structural modifications only, for instance a header
// with <span> classes inside.

// These should not contain classes/prototypes to handle icon fonts. Those should be handles in a separate icons partial.

// Fonts
// --------------------------------------------------------
@mixin fontBase {
  font-family: 'URWGeometric';
  font-weight: $light;
}

@mixin fontSign {
  font-family: 'URWGeometric-Black';
  font-weight: $regular;
}

// Utility classes
// --------------------------------------------------------
.uppercase {
  text-transform: uppercase;
}

.small-type {
  font-size: 16px;
}

.accent-type {
  color: $accentPrimary
}

.semibold {
  font-weight: $semibold;
}

.bold {
  font-weight: $extrabold;
}

// Headings
// --------------------------------------------------------
// No mixins as these can't be used in content
.sign-primary {
  @include fontSign;
  font-size: 72px;
  line-height: 0.8;

  @include respond($break40) {
    font-size: 100px;
  }

  @include respond($break90) {
    font-size: 135px;
  }
}

.sign-secondary {
  @include fontSign;
  font-size: 64px;
  line-height: 1;

  @include respond($break40) {
    font-size: 85px;
  }

  @include respond($break90) {
    font-size: 120px;
  }
}

.sign-tertiary {
  font-weight: $light;
  font-size: 36px;

  @include respond($break70) {
    font-size: 48px;
  }

  @include respond($break80) {
    font-size: 63px;
  }
}

@mixin headingPrimary {
  @include fontBase;
  font-size: 36px;
  font-weight: $light;
  line-height: 1.15;

  @include respond($break60) {
    font-size: 43px;
  }
}

@mixin headingSecondary {
  font-size: 23px;
  font-weight: $light;
  line-height: 1.238;

  @include respond($break60) {
    font-size: 25px;
  }
}

@mixin headingTertiary {
  @include fontSign;
  font-size: 16px;
  line-height: 1.333;

  @include respond($break40) {
    font-size: 18px;
  }
}

.heading-primary {
  @include headingPrimary;
}

.heading-secondary {
  @include headingSecondary;
}

.heading-tertiary {
  @include headingTertiary;
}

@mixin headingQuaternary {
  font-size: 28px;
  letter-spacing: 0.005em;
  line-height: 0.92;
  font-weight: $extrabold;

  @include respond($break65) {
    font-size: 36px;
  }

  @include respond($break80) {
    font-size: 43px;
  }
}

.heading-quaternary {
  @include headingQuaternary;
}

// Utility
// --------------------------------------------------------
@mixin utilityPrimary {
  @include fontSign;
  font-size: 19px;
  text-transform: uppercase;
}

@mixin utilitySecondary {
  font-size: 23px;
  letter-spacing: -0.01em;
  font-weight: $extrabold;
  text-transform: uppercase;
}

// Form buttons
@mixin utilityTertiary {
  @include fontSign;
  font-size: 16px;
  text-transform: uppercase;
}