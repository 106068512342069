// Default content block with border/overhang
.content-block-primary {
  // Scoped variables
  $borderSize: 7px;
  $marginOffsetMax: 17px;
  $marginOffset: 12px;

  .wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    @include respond($break60) {
      padding: $marginOffset $marginOffset 0 0;
    }

    @include respond($break100) {
      padding: $marginOffsetMax $marginOffsetMax 0 0;
    }

    .left & {
      @include respond($break60) {
        padding: $marginOffset 0 0 $marginOffset;
      }

      @include respond($break100) {
        padding: $marginOffsetMax 0 0 $marginOffsetMax;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 23px 18px 28px 13px;

    @include respond($break60) {
      width: 55%;
      min-width: calc(100% - 450px);
      padding: 23px 25px 0 13px;

      &.no-image {
        width: 100%;
      }

      &.video, &.image {
        width: 45%;
        min-width: 0;
      }
    }

    .left & {
      padding: 23px 13px 28px 18px;

      @include respond($break60) {
        padding: 23px 13px 0 25px;
      }
    }

    .arrow-right-link {
      margin-top: 28px;
      padding-bottom: 8px;

      @include respond($break60) {
        position: relative;
      }
    }
  }

  .body-two-column {
    display: block;
    padding: 23px 18px 28px 13px;
    padding-top: 30px;
    width: 100%;

    @include respond($break60) {
      padding: 23px 25px 0 13px;
    }

    @include respond($break65) {
      display: flex;
      width: calc(100% + 5.5vw);
      margin-left: -5.5vw;
    }

    @include respond($break100) {
      width: calc(100% + 62px);
      margin-left: -62px;
    }


    .col {
      width: 100%;

      &.limit {
        @include respond($break65) {
          width: 375px;
          max-width: 50%;
        }
      }

      @include respond($break65) {
        padding-left: 5.5vw;
        width: 50%;
      }

      @include respond($break100) {
        padding-left: 62px;
      }

      + .col {
        margin-top: 48px;

        @include respond($break65) {
          margin: 0;
        }
      }
    }
  }

  .featured-image.right, .featured-image.left {
    display: none;

    @include respond($break60) {
      display: block;
      padding: 23px 34px 0 0;
      width: 55%;

      .left & {
        padding: 23px 0 0 34px;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .featured-image.narrow {
    width: 100%;
    margin-bottom: 32px;

    @include respond($break60) {
      display: none;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .featured-video {
    width: 100%;

    .left & {
      padding: 18px 0 0 18px;
    }

    @include respond($break60) {
      padding: 23px 34px 0 0;
      width: 55%;

      .left & {
        padding: 23px 0 0 34px;
      }
    }
  }

  &.bottom-padding-small {
    padding-bottom: 30px;
  }

  footer {

  }
}
