.site-header {
  z-index: 10;

  .hamburger-overlay {
    z-index: 5;
  }
}

body.modal-is-open::after {
  z-index: 15;
}

.m-modal {
  z-index: 20;
}
