.direct-mail {
  // Special form
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  input[type="text"], input[type="email"] {
    background-color: $neutral20;
  }

  .checkbox {
    font-size: 0.938em;
    margin-top: 0.467em;

    .toggle-indicator {
      background-color: $neutral20;
    }
  }

  .name {
    width: 100%;
    font-size: 0.938em;

    @include respond($break60) {
      width: 40%;
    }
  }

  .email {
    width: 100%;
    margin-top: 6px;
    font-size: 0.938em;

    @include respond($break60) {
      width: 50%;
      margin: 0;
    }
  }

  .submit {
    @include iconPre($arrowRight);
    @include buttonUnstyled;
    font-size: 1em;
    display: none;
    min-width: 0; // OD
    background-color: $accentSecondary;
    border: 0; // OD
    margin: 0; // OD

    @include respond($break60) {
      display: block;
    }

    &.narrow {
      display: block;
      margin-top: 7px;

      @include respond($break60) {
        display: none;
      }
    }

    &::after {
      display: none; // OD
    }

    &::before {
      color: $neutralWhite;
      width: 1.25em;
      padding-top: 5px;
      font-size: 1.5em;
    }
  }

  .messaging {
    width: 100%;
    margin-top: -1.5em;
    padding-bottom: 1.5em;

    p {
      font-size: 0.938em;
    }

    &.success {
      color: $accentSecondary;
    }

    &.error {
      color: $error;
    }
  }
}