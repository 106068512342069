// Relative font sizes so that it can be resized in Kiosk
.match-landing {
  .kiosk & {
    padding: 12.407vw 0;
  }

  .body {
    margin: 0 auto;
    max-width: 592px;
    font-size: 25px;
    font-weight: $light;
    color: $accentPrimary;
    padding-bottom: 1.8em;

    .kiosk & {
      font-size: 2.605vw;
      max-width: 61.228vw;
    }
  }

  .button-callout {
    .kiosk & {
      font-size: 3.846vw;
    }

    .arrow-right:after {
      background-color: $accentSecondary;
    }

    .kiosk & {
      .arrow-right:after {
        background-color: $accentTertiary;
      }
    }
  }
}
