$_arrow-down-top: 5.154vw;

.hero {
  padding-bottom: 56px;


  @include respond($break40) {
    padding-bottom: 16.5vw;
  }

  @include respond($break60 + 1, "min") {
    &.hero-interior--landscape {
      padding-bottom: 16.5vw - $_arrow-down-top;
    }
  }


  @include respond($break130) {
    padding-bottom: 215px;

    &.hero-interior {
      padding-bottom: 140px;
    }
  }

  &.flush-bottom {
    padding-bottom: 0;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rel {
    width: 100%;
  }

  .arrow-down-link {
    position: absolute;
    right: 0;
    top: $_arrow-down-top;
    margin-top: -12px;
    display: block;
    visibility: visible;
    opacity: 1;
    transition: margin $duration $timing,
      visibility $duration $timing,
      opacity $duration $timing;

    @include respond($break90) {
      margin-top: 0;
    }

    @include respond($break130) {
      top: 67px;
    }

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transition: margin $duration $timing,
      visibility $duration $timing,
      opacity $duration $timing;
    }
  }

  header, .heading-secondary {
    width: 100%;
    color: $accentPrimary;
    padding-right: 55px;

    @include respond($break60) {
      padding-right: 20px;
    }
  }

  .sign-primary {
    padding-bottom: 0.044em;

    @include respond($break60) {
      padding-top: 42px;
    };

    .accent-tertiary & {
      color: $accentTertiary;
    }
  }

  .sign-secondary {
    .accent-tertiary & {
      color: $accentTertiary;
      line-height: 0.95;
    }

    @include respond($break60) {
      padding-top: 54px;
    }
  }

  .heading-secondary {
    padding-top: 0.96em;

    @include respond($break40) {
      padding-top: 0.96em;
    }
  }

  .sign-tertiary {
    color: $accentSecondary;
  }

  .circle-link {
    position: absolute;
    top: 20px;
    right: 0;

    @include respond($break60) {
      position: static;
      margin-left: -70px;
    }
  }

  .hero-image, .hero-video {
    display: none;

    @include respond($break60) {
      display: block;
      min-width: 32%;
    }

    @include respond($break90) {
      min-width: 37%;
    }

    img {
      width: 100%;
      height: auto;
    }

    video {
      width: 100%;
      height: auto;
    }
  }

  .hero-logo {
    max-width: 85%;
  }

  .utility {
    position: relative;
    width: 0;

    @include respond($break60) {
      min-width: 34px;
      width: 34px;
      padding-top: 42px;
    }
  }

  .social {
    display: none;
    padding-top: 10px;

    @include respond($break60) {
      display: block;
    }

    li, a {
      display: block;
      text-align: right;
    }

    a {
      font-size: 24px;
      color: $accentPrimaryLight;

      svg {
        inline-size: 24px;
        block-size: 24px;
      }
    }
  }
  &.hero-interior {

    .arrow-down-link {

      @include respond($break130) {
        top: 50px;
      }
    }

    &--landscape {
      @include respond($break60, "max") {
        .hero-image + .heading-secondary {
          display: none;
        }
      }

      @include respond($break60 + 1, "min") {
        .container:first-child {
          display: grid;
          grid-template:
            "header header" auto
            "image image" auto
            / 1fr auto;
        }

        header {
          grid-area: header;
          -ms-grid-row: 2;
          -ms-grid-column: 1;

          .heading-secondary {
            display: none;
          }
        }

        .hero-image, .hero-video {
          grid-area: image;
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }

        .utility {
          grid-area: utility;
          -ms-grid-row: 1;
          -ms-grid-row-span: 2;
          -ms-grid-column: 2;
        }

        .arrow-down-link {
          top: auto;
          bottom: 0;
        }
      }
    }
  }

  .indent {
    
    @include respond($break40) {
      margin-left: 11vw;
    }
  
    @include respond($break100) {
      margin-left: 10vw;
    }
  
  
    @include respond($break130) {
      margin-left: 130px;
    }
  }
}
